<template>
  <ValidationObserver>
      <b-card title="Update Vendor">
        <div class="form-group form-row">
          <label class="col-4 col-form-label">Nama Vendor</label>
          <div class="col">
            <p>{{ this.Payload.vendor.vendor_name }}</p>
          </div>
        </div>
        <div class="form-group form-row">
          <label class="col-4 col-form-label">Foto Vendor</label>
          <div class="col">
            <img :src="this.Payload.vendor.vendor_imageurl" alt="Foto Vendor" width="200">
          </div>
        </div>
        <div class="form-group form-row">
          <label class="col-4 col-form-label">nama Kreator</label>
          <div class="col">
            <p>{{ this.Payload.user.creator_name }}</p>
          </div>
        </div>
        <div class="form-group form-row">
          <label class="col-4 col-form-label">Foto Kreator</label>
          <div class="col">
            <img :src="this.Payload.user.creator_imageurl" alt="Foto Vendor" width="200">
          </div>
        </div>
        <div class="form-group form-row">
          <label class="col-4 col-form-label">Email Kreator</label>
          <div class="col">
            <p>{{ this.Payload.user.creator_email }}</p>
          </div>
        </div>
        <div class="form-group form-row">
          <label class="col-4 col-form-label">Jenis Projek</label>
          <div class="col">
            <p>{{ this.Payload.jenis_project }}</p>
          </div>
        </div>
        <div class="form-group form-row">
          <label class="col-4 col-form-label">Dimensi Projek</label>
          <div class="col">
            <p>{{ this.Payload.dimension_project }}</p>
          </div>
        </div>
        <div class="form-group form-row">
          <label class="col-4 col-form-label">Nomor Whatsapp</label>
          <div class="col">
            <p>{{ this.Payload.no_wa }}</p>
          </div>
        </div>
        <div class="form-group form-row">
          <label class="col-4 col-form-label">Lokasi Projek</label>
          <div class="col">
            <p>{{ this.Payload.lokasi_project }}</p>
          </div>
        </div>  
        <div class="form-group form-row">
          <label class="col-4 col-form-label">Brief Projek</label>
          <div class="col">
            <p>{{ this.Payload.brief_project }}</p>
          </div>
        </div>  
        <div class="form-group form-row">
          <label class="col-4 col-form-label">Timeline Project</label>
          <div class="col">
            <p>{{ this.Payload.timeline_project }}</p>
          </div>
        </div>  
        <div class="form-group form-row">
          <label class="col-4 col-form-label">Link Gmeet</label>
          <div class="col">
            <p>{{ this.Payload.link_gmeet }}</p>
          </div>
        </div>  
        <div class="form-group form-row">
          <label class="col-4 col-form-label">Status</label>
          <div class="col">
            <p>{{ this.Payload.status.name }}</p>
          </div>
        </div>  
        <div class="form-group form-row">
          <label class="col-4 col-form-label">Deal / TIdak Deal</label>
          <div class="col">
            <p>{{ this.Payload.is_deal.name }}</p>
          </div>
        </div>  
        <div class="form-group form-row">
          <label class="col-4 col-form-label">Rating</label>
          <div class="col">
            <p>{{ !this.Payload.is_rating == false ? this.Payload.is_rating.name : null }}</p>
          </div>
        </div>  
        <div class="form-group form-row">
          <label class="col-4 col-form-label">Tanggal Dibuat</label>
          <div class="col">
            <p>{{ formatDate(this.Payload.date_created) }}</p>
          </div>
        </div>  
        <!-- <div class="form-group form-row">
          <label class="col-4 col-form-label">brief_project_file</label>
          <div class="col">
            <a href="" download="brief_project_file"></a>
          </div>
        </div> -->
      </b-card>
  </ValidationObserver>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormFile,
  BButton,
  BSpinner,
  BFormInput,
  BFormTextarea,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { required } from "@validations";
import flatPickr from "vue-flatpickr-component";
import { VueEditor } from "vue2-editor";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
} from "vee-validate";
import { successNotification, errorNotification } from "@/auth/utils";
configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid",
    dirty: ["is-dirty", "is-dirty"], // multiple classes per flag!
    // ...
  },
});
export default {
  components: {
    BCard,
    BCol,
    BRow,
    BButton,
    BSpinner,
    BFormFile,
    BFormInput,
    BFormTextarea,
    VueEditor,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      isLoading: false,
      customToolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic"],
        ["image", "video"],
        ["link"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      ],
      snowOption: {
        theme: "snow",
      },
      Payload:[]
    };
  },
  setup(props) {
    return {
      successNotification,
      errorNotification,
    };
  },

  created() {
    this.loadData();
  },

  methods: {
    handleBackButton() {
      history.back();
    },
    loadData() {
      const params = this.$route.params.slug;
      this.loadData = true;
      this.$http
        .get("/admin/vendor-booking/" + params)
        .then((response) => {
          this.Payload = response.data.data;
        })
        .catch((error) => {
        })
        .finally(() => {
          this.loadData = false;
        });
    },
    formatDate(dateStr) {
			const date = new Date(dateStr);
			const options = { day: 'numeric', month: 'long', year: 'numeric' };
			return date.toLocaleDateString('id-ID', options);
		},
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
